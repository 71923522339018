








import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import UserFormTemplate from '@/components/users/UserFormTemplate.vue';
import { CreateUserForAxios, MultiUserFormRequest } from '@/models/users';
import { UserStores } from '@/store/users';
import { DropdownItem } from '@/models/dropdown';
import { uploadUserProfilePicture } from '@/utils/attachments';
@Component({
  components: { Page, BackButtonLayout, UserFormTemplate },
})
export default class CreateUsers extends Vue {
  userCreateStore = UserStores.create;
  userDetailStore = UserStores.detail;
  userStateStore = UserStores.state;
  titles: DropdownItem[] = [];

  get layoutTitle() {
    return `Add New User`;
  }

  get backRoute() {
    return `/users`;
  }

  createNewUser(payload: MultiUserFormRequest) {
    const parsedPayload: CreateUserForAxios = {
      ...payload,
    };
    this.userCreateStore.createNewUser(parsedPayload);
  }

  mounted() {
    this.userDetailStore.retrieveTitles();
  }

  @Watch(`userCreateStore.response`)
  redirectToUserListing() {
    if (this.userCreateStore.response) {
      if (typeof this.userCreateStore.userProperties!.profile_url !== 'string' && this.userCreateStore.userProperties!.profile_url.originalFile) {
        uploadUserProfilePicture(this.userCreateStore.userProperties!.profile_url.originalFile , this.userCreateStore.userProperties!.email);
      }
      this.userStateStore.setIsModified(true);
      this.$router.push({
        path: `/users`,
      });
    }
  }

  @Watch(`userDetailStore.fetchedTitleResponse`)
  setTitles() {
    if (this.userDetailStore.fetchedTitleResponse) {
      this.titles = [];
      this.userDetailStore.fetchedTitleResponse.objects.forEach((item) => {
        this.titles.push({
          text: item.name,
          value: item.id.toString(),
          uniqueKey: `${item.name}_1`,
        });
      });
    }
  }
}
